var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 d-flex" },
    [
      _c("v-select", {
        staticClass: "grow-flex-auto border-radius-supplier",
        class: {
          "is-invalid": _vm.menus.length === 0,
        },
        attrs: {
          id: "supplier-selector",
          placeholder: _vm.selectTitle,
          options: _vm.options,
          label: "value",
          reduce: (option) => option.index,
          selectable: _vm.disableSelectItem,
          clearable: false,
        },
      }),
      _c("div", { staticClass: "d-flex flex-row icon-group-border-append" }, [
        _c(
          "div",
          {
            staticClass:
              "cursor-pointer d-inline-flex justify-content-center align-items-center",
            style: `width: ${_vm.defaultIconWidth}`,
            attrs: { id: `choice-mandatory-${_vm.index}` },
            on: { click: _vm.updateChoice },
          },
          [
            _c(_vm.getLucideIcon("ListPlus"), {
              tag: "component",
              attrs: { color: "#00A09C", size: 22, "stroke-width": 1.75 },
            }),
            _c(
              "b-tooltip",
              {
                attrs: {
                  show: _vm.showTooltip,
                  target: `choice-mandatory-${_vm.index}`,
                  placement: "left",
                },
                on: {
                  "update:show": function ($event) {
                    _vm.showTooltip = $event
                  },
                },
              },
              [
                _c("div", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.FormMSG(2, "Add/Update List Choice"))),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm.isDeletable
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.top",
                    modifiers: { hover: true, top: true },
                  },
                ],
                staticClass:
                  "cursor-pointer d-inline-flex justify-content-center align-items-center",
                staticStyle: { "border-left": "1px solid #cad0d7" },
                style: `width: ${_vm.defaultIconWidth}`,
                attrs: { title: _vm.FormMSG(3, "Delete") },
                on: { click: _vm.remove },
              },
              [
                _c(_vm.getLucideIcon("XCircle"), {
                  tag: "component",
                  attrs: { color: "#EA4E2C", size: 18, "stroke-width": 2.25 },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("choice-modal", {
        attrs: { field: _vm.item },
        on: {
          "custom-field-choice-modal:closed":
            _vm.onCustomFieldChoiceModalClosed,
          "custom-field-choice-modal:choice-list-submitted":
            _vm.onCustomFieldChoiceListSubmitted,
          "custom-field-choice-modal:choice-list-reloaded":
            _vm.onCustomFieldChoiceListReloaded,
        },
        model: {
          value: _vm.isCustomFieldChoiceModalOpen,
          callback: function ($$v) {
            _vm.isCustomFieldChoiceModalOpen = $$v
          },
          expression: "isCustomFieldChoiceModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="choiceTitle"
		size="lg"
		centered
		cancel-variant="light"
		@hidden="emitEventClose"
		no-close-on-backdrop
		no-close-on-esc
		@shown="reloadData"
	>
		<div
			:class="{
				'container-layout': $screen.width > 576,
				'container-mobile': $screen.width <= 576
			}"
			class="px-0"
		>
			<b-row>
				<b-col>
					<div class="w-100">
						<b-card no-body class="card-border-blue-light mb-0" style="background-color: #f8f9fb">
							<choice-item-form
								ref="form"
								:field="field"
								:is-data-exist="isDataExist"
								:choice-list="choiceList"
								@custom-field-choice-form-list:submit="onItemListSubmitted"
								@custom-field-choice-form-list:reload="onItemListReloaded"
							/>
						</b-card>
					</div>
				</b-col>
			</b-row>
		</div>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button
					size="sm"
					variant="custom-outline-gray"
					style="margin-top: 5px"
					class="w-138-px mr-3"
					:disabled="watingSubmitForm"
					@click="emitEventClose"
					block
				>
					{{ FormMSG(2, 'Close') }}
				</b-button>
				<b-button size="sm" variant="primary" class="w-138-px" :disabled="watingSubmitForm || choiceList.length === 0" @click="dispatchEvent" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-show="watingSubmitForm" small />
						<div :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(3, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import ChoiceItemForm from '@/components/CustomField/Choice/Form';
import _ from 'lodash';
// import {  } from '@/cruds/custom-field.crud';

export default {
	name: 'CustomFieldChoiceModal',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		ChoiceItemForm
	},
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		field: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		},
		choiceTitle() {
			return this.FormMSG(1, 'Choices :: ') + this.field.label;
		}
	},
	data() {
		return {
			watingSubmitForm: false,
			choiceList: [],
			isDataExist: false
		};
	},
	methods: {
		emitEventClose() {
			if (this.choiceList.length > 0) {
				let items = this.choiceList;
				for (let i = 0; i < items.length; i++) {
					if (+items[i].id === 0 && _.isEmpty(items[i].value)) {
						items.splice(i, 1);
					}
				}
				this.choiceList = items;
			}
			this.$emit('custom-field-choice-modal:closed');
		},
		dispatchEvent(e) {
			e.preventDefault();
			this.$refs['form'].$refs['submit'].click();
		},
		onItemListSubmitted(payload) {
			this.choiceList = this.choiceList.map((choice) => {
				for (let i = 0; i < payload.length; i++) {
					if (+choice.index === +payload[i].index) {
						choice = {
							...choice,
							value: payload[i].value
						};
					}
				}

				return choice;
			});
			this.$emit('custom-field-choice-modal:choice-list-submitted', payload);
		},
		reloadData() {},
		onItemListReloaded() {
			this.$emit('custom-field-choice-modal:choice-list-reloaded');
		}
	},
	watch: {
		field: {
			handler(value) {
				if (!_.isNil(value)) {
					this.choiceList = _.isArray(value.customFieldMenuChoiceList) ? value.customFieldMenuChoiceList : [];
					this.isDataExist = this.choiceList.length > 0;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss"></style>

<template>
	<b-tr class="animated fadeIn" v-if="choice">
		<b-td style="vertical-align: middle; width: 3.5%" v-b-tooltip.hover.left :title="FormMSG(4, 'Drag && Drop')">
			<component :is="getLucideIcon('LayoutGrid')" color="#CAD0D7" class="cursor-pointer mr-1" :size="22" :stroke-width="2.5" />
		</b-td>
		<b-td style="vertical-align: middle; width: 2%" v-if="choice.id > 0">
			<b-form-checkbox
				v-model="choice.disabled"
				:title="checkboxTitle"
				v-b-tooltip.hover.left
				size="lg"
				@change="handleCheckboxChanged($event, 'disabled')"
			/>
		</b-td>
		<b-td v-if="choice.id === 0 && existDataAlreadySubmitted"></b-td>
		<b-td :style="`width: ${choice.id > 0 ? '91' : '93'}`">
			<b-form-input
				ref="value"
				type="text"
				autocomplete="off"
				v-model="choice.value"
				:class="{
					'is-invalid': $v.choice.value.$error
				}"
				@keyup="handleInputChanged('value')"
			/>
		</b-td>
		<b-td
			style="vertical-align: middle; width: 3.5%"
			class="text-right"
			v-b-tooltip.hover.right
			:title="FormMSG(1, 'Remove')"
			v-if="CUSTOM_FIELD_TYPE.Radiobutton !== field.type"
		>
			<component :is="getLucideIcon('MinusCircle')" color="#EA4E2C" class="cursor-pointer mr-1" :size="20" :stroke-width="3" @click="removeItem" />
		</b-td>
	</b-tr>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { INPUT_TYPE, CUSTOM_FIELD_TYPE } from '@/shared/constants';
// import {  } from '@/cruds/custom-field.crud';

export default {
	name: 'CustomFieldChoiceItemForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	props: {
		item: {
			type: Object,
			required: false,
			default: null
		},
		index: {
			type: Number,
			required: false,
			default: 0
		},
		existDataAlreadySubmitted: {
			type: Boolean,
			required: false,
			default: false
		},
		field: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		checkboxTitle() {
			return this.choice.disabled === false ? this.FormMSG(2, 'Disable') : this.FormMSG(3, 'Enable');
		}
	},
	data() {
		return {
			choice: null,
			INPUT_TYPE,
			CUSTOM_FIELD_TYPE
		};
	},
	mounted() {
		this.$refs.value.focus();
	},
	methods: {
		removeItem() {
			this.$emit('custom-field-choice-item-form:removed', {
				index: this.index,
				id: +this.choice.id
			});
		},
		handleCheckboxChanged(payload, fieldName) {
			this.choice[fieldName] = payload;

			this.$emit('custom-field-choice-item-form:updated', {
				index: this.index,
				item: this.choice
			});
		},
		handleInputChanged(fieldName, type = 0) {
			if (type === INPUT_TYPE.INTEGER) {
				this.choice[fieldName] = +this.choice[fieldName];
			} else if (type === INPUT_TYPE.FLOAT) {
				this.choice[fieldName] = parseFloat(this.choice[fieldName]);
			}

			this.$emit('custom-field-choice-item-form:updated', {
				index: this.index,
				item: this.choice,
				isLineValid: !this.$v.$invalid
			});
		}
	},
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					this.choice = _.cloneDeep(value);
					this.$v.$touch();
				}
			},
			deep: true,
			immediate: true
		}
	},
	validations() {
		return {
			choice: {
				value: {
					required,
					min: minLength(2)
				}
			}
		};
	}
};
</script>
<style lang="scss"></style>

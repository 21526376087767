var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex flex-column justify-content-center align-items-center",
                },
                [
                  _vm.$screen.width >= 992 && _vm.items.length > 0
                    ? _c(
                        "b-table-simple",
                        { attrs: { small: "", borderless: true } },
                        [
                          _c(
                            "b-thead",
                            { staticStyle: { color: "rgba(6, 38, 62, 0.84)" } },
                            [
                              _c(
                                "b-tr",
                                { staticStyle: { "line-height": "8px" } },
                                [
                                  _c("b-th"),
                                  _vm.items.length > 0 ? _c("b-th") : _vm._e(),
                                  _c("b-th", [
                                    _vm._v(_vm._s(_vm.FormMSG(3, "Label"))),
                                  ]),
                                  _c("b-th"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tbody",
                            { staticClass: "text-center" },
                            _vm._l(_vm.items, function (item, i) {
                              return _c("item-form", {
                                key: i,
                                ref: _vm.generateSecureId(
                                  `choice-item-form-${i}`
                                ),
                                refInFor: true,
                                attrs: {
                                  index: i,
                                  item: item,
                                  field: _vm.field,
                                  "exist-data-already-submitted":
                                    _vm.existDataAlreadySubmitted,
                                },
                                on: {
                                  "custom-field-choice-item-form:updated":
                                    _vm.onLineTableChanged,
                                  "custom-field-choice-item-form:removed":
                                    _vm.onLineTableRemoved,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$screen.width >= 992 && _vm.items.length === 0
                    ? _c(
                        "div",
                        {
                          staticClass: "text-center py-3",
                          staticStyle: { "font-size": "1rem" },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(1, "Please, add a new choice")
                              ) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "d-flex justify-content-end w-100" },
                [
                  _vm.CUSTOM_FIELD_TYPE.Radiobutton !== _vm.field.type
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "w-200-px d-flex justify-content-center align-items-center",
                          attrs: { size: "sm", variant: "outline-primary" },
                          on: { click: _vm.addNewChoice },
                        },
                        [
                          _c(_vm.getLucideIcon("PlusCircle"), {
                            tag: "component",
                            attrs: { size: 16, "stroke-width": 2 },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "px-2",
                              staticStyle: { "margin-bottom": "-2px" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(2, "Add new choice")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center mt-2",
                  style: `display: ${
                    _vm.displayAddBtn ? "block" : "none"
                  } !important;`,
                  attrs: {
                    sm: "4",
                    md: "4",
                    lg: "4",
                    xl: "4",
                    "offset-sm": "5",
                    "offset-md": "5",
                    "offset-lg": "5",
                    "offset-xl": "5",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      ref: "submit",
                      staticClass: "w-138-px",
                      attrs: {
                        type: "submit",
                        size: "sm",
                        variant: "outline-primary",
                        block: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(4, "Save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-warning",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            "header-bg-variant": "warning",
            title: _vm.FormMSG(5, "Warning"),
            "ok-variant": "warning",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.warningMissingFlagItemModal = false
            },
          },
          model: {
            value: _vm.warningMissingFlagItemModal,
            callback: function ($$v) {
              _vm.warningMissingFlagItemModal = $$v
            },
            expression: "warningMissingFlagItemModal",
          },
        },
        [
          _c("div", { staticStyle: { "font-size": "1rem" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.FormMSG(
                    6,
                    "Please fill the field(s) required (coloring red) or remove one or all of them."
                  )
                ) +
                "\n\t\t"
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
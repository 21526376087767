<template>
	<div class="animated fadeIn">
		<b-form @submit.prevent="submitForm">
			<b-row>
				<b-col class="d-flex flex-column justify-content-center align-items-center">
					<b-table-simple small :borderless="true" v-if="$screen.width >= 992 && items.length > 0">
						<b-thead style="color: rgba(6, 38, 62, 0.84)">
							<b-tr style="line-height: 8px">
								<b-th></b-th>
								<b-th v-if="items.length > 0"></b-th>
								<b-th>{{ FormMSG(3, 'Label') }}</b-th>
								<b-th></b-th>
							</b-tr>
						</b-thead>
						<b-tbody class="text-center">
							<item-form
								v-for="(item, i) in items"
								:ref="generateSecureId(`choice-item-form-${i}`)"
								:key="i"
								:index="i"
								:item="item"
								:field="field"
								:exist-data-already-submitted="existDataAlreadySubmitted"
								@custom-field-choice-item-form:updated="onLineTableChanged"
								@custom-field-choice-item-form:removed="onLineTableRemoved"
							/>
						</b-tbody>
					</b-table-simple>
					<div class="text-center py-3" style="font-size: 1rem" v-if="$screen.width >= 992 && items.length === 0">
						{{ FormMSG(1, 'Please, add a new choice') }}
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="d-flex justify-content-end w-100">
					<b-button
						size="sm"
						variant="outline-primary"
						class="w-200-px d-flex justify-content-center align-items-center"
						@click="addNewChoice"
						v-if="CUSTOM_FIELD_TYPE.Radiobutton !== field.type"
					>
						<component :is="getLucideIcon('PlusCircle')" :size="16" :stroke-width="2" />
						<div class="px-2" style="margin-bottom: -2px">
							{{ FormMSG(2, 'Add new choice') }}
						</div>
					</b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					class="d-flex justify-content-center mt-2"
					sm="4"
					md="4"
					lg="4"
					xl="4"
					offset-sm="5"
					offset-md="5"
					offset-lg="5"
					offset-xl="5"
					:style="`display: ${displayAddBtn ? 'block' : 'none'} !important;`"
				>
					<b-button ref="submit" type="submit" size="sm" variant="outline-primary" class="w-138-px" block>{{ FormMSG(4, 'Save') }}</b-button>
				</b-col>
			</b-row>
		</b-form>
		<b-modal
			header-class="header-class-modal-doc-package"
			header-bg-variant="warning"
			:title="FormMSG(5, 'Warning')"
			class="modal-warning"
			v-model="warningMissingFlagItemModal"
			@ok="warningMissingFlagItemModal = false"
			ok-variant="warning"
			ok-only
		>
			<div style="font-size: 1rem">
				{{ FormMSG(6, 'Please fill the field(s) required (coloring red) or remove one or all of them.') }}
			</div>
		</b-modal>
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import ItemForm from '@/components/CustomField/Choice/ItemForm';
import { generateSecureId } from '@/shared/utils';
import { deleteCustomFieldMenuChoices } from '@/cruds/custom-field.crud';
import { CUSTOM_FIELD_TYPE } from '@/shared/constants';
import { store } from '@/store';

const choice = {
	id: 0,
	customFieldId: 0,
	customFieldMenuChoiceId: 0,
	value: '',
	language: 0
};

export default {
	name: 'CustomFieldChoiceFormList',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		ItemForm
	},
	props: {
		field: {
			type: Object,
			required: false,
			default: null
		},
		choiceList: {
			type: Array,
			required: true,
			default: () => []
		},
		displayAddBtn: {
			type: Boolean,
			required: false,
			default: false
		},
		isDataExist: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		defaultLanguage() {
			return store.appLanguage();
			/**
			 * @TODO
			 * To make dynamic
			 * By now, lets start with 0 = English
			 */
			// return 0;
		}
	},
	data() {
		return {
			items: [],
			generateSecureId,
			isValidChoices: [],
			addUpdChoiceItems: [],
			existDataAlreadySubmitted: false,
			warningMissingFlagItemModal: false,
			CUSTOM_FIELD_TYPE,
			store
		};
	},
	created() {
		if ([CUSTOM_FIELD_TYPE.Radiobutton].includes(+this.field.type)) {
			if (+this.field.id === 0 && this.choiceList.length === 0) {
				for (let i = 0; i < 2; i++) {
					this.choiceList.push(choice);
					this.isValidChoices.push({
						index: i,
						isValid: false
					});
				}
			}
		}
	},
	methods: {
		async submitForm(e) {
			e.preventDefault();

			if (!this.validChoices()) {
				this.warningMissingFlagItemModal = true;

				return;
			}

			// ADD && UPDATE
			this.$emit('custom-field-choice-form-list:submit', this.addUpdChoiceItems);
		},
		addNewChoice() {
			let newChoice = {
				...choice,
				language: this.defaultLanguage
			};
			this.items.push(newChoice);
			this.isValidChoices.push({
				index: this.items.length - 1,
				isValid: false
			});
		},
		onLineTableChanged({ index, item, isLineValid }) {
			if (this.addUpdChoiceItems.length === 0) {
				this.addUpdChoiceItems.push({
					...item,
					index
				});
			} else {
				const idx = this.addUpdChoiceItems.findIndex((choiceItem) => choiceItem.index === index);
				if (idx === -1) {
					this.addUpdChoiceItems.push({
						...item,
						index
					});
				} else {
					this.addUpdChoiceItems[idx] = Object.assign(this.addUpdChoiceItems[idx], item);
				}
			}

			const indexValidator = this.isValidChoices.findIndex((valid) => valid.index === index);
			if (indexValidator > -1) {
				this.isValidChoices[indexValidator] = {
					...this.isValidChoices[indexValidator],
					isValid: isLineValid
				};
			}
			// console.log({ [index]: isLineValid });
		},
		onLineTableRemoved({ index, id }) {
			if (this.field.id === 0) {
				this.addUpdChoiceItems = this.items;
			}

			if (id === 0) {
				this.items.splice(index, 1);
				const idx = this.addUpdChoiceItems.findIndex((choiceItem) => choiceItem.index === index);
				if (idx !== -1) {
					this.addUpdChoiceItems.splice(idx, 1);
				}

				const indexValidator = this.isValidChoices.findIndex((valid) => valid.index === index);
				if (indexValidator !== -1) {
					this.isValidChoices.splice(indexValidator, 1);
				}
				// console.log({ idx, index, id, items: this.items, lineTableRemovedChoiceItems: this.addUpdChoiceItems });
			} else {
				const action = async () => {
					await deleteCustomFieldMenuChoices([id])
						.then(() => {
							this.items.splice(index, 1);
							const idx = this.addUpdChoiceItems.findIndex((choiceItem) => choiceItem.index === index);
							if (idx !== -1) {
								this.addUpdChoiceItems.splice(idx, 1);
							}

							const indexValidator = this.isValidChoices.findIndex((valid) => valid.index === index);
							if (indexValidator !== -1) {
								this.isValidChoices.splice(indexValidator, 1);
							}

							this.$emit('custom-field-choice-form-list:reload');
						})
						.catch((error) => {
							console.error({ customFieldChoiceFormListRemoveError: error });
						});
				};
				this.confirmModal(action, this.FormMSG(7, 'Are you sure to remove this choice ?'), 'danger');
			}

			if (this.items.length === 0) {
				this.existDataAlreadySubmitted = false;
			} else {
				const existTrueData = this.items.filter((choiceItem) => +choiceItem.id > 0);
				this.existDataAlreadySubmitted = existTrueData.length > 0;
			}
		},
		validChoices() {
			if (this.isValidChoices.length === 0) return true;

			let validChoices = [];
			for (const valid of this.isValidChoices) {
				validChoices.push(valid.isValid);
			}
			const isValidChoices = validChoices.join(',');

			return !/false/.test(isValidChoices);
		}
	},
	watch: {
		choiceList: {
			handler(value) {
				this.items = value;

				let inc = 0;
				for (const item of this.items) {
					const indexValidator = this.isValidChoices.findIndex((valid) => valid.index === inc);
					if (indexValidator === -1) {
						this.isValidChoices.push({
							index: inc,
							isValid: true
						});
					}
					inc++;
				}
			},
			deep: true,
			immediate: true
		},
		isDataExist: {
			handler(value) {
				this.existDataAlreadySubmitted = value;
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss"></style>

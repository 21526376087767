var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field
    ? _c(
        "b-tr",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-td",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.left",
                  modifiers: { hover: true, left: true },
                },
              ],
              staticStyle: { "vertical-align": "middle", width: "3.5%" },
              attrs: { title: _vm.FormMSG(1, "Drag && Drop") },
            },
            [
              _c(_vm.getLucideIcon("LayoutGrid"), {
                tag: "component",
                staticClass: "cursor-pointer mr-1",
                attrs: { color: "#CAD0D7", size: 22, "stroke-width": 2.5 },
              }),
            ],
            1
          ),
          +_vm.field.id > 0 && _vm.existDataAlreadySubmitted && _vm.isDev
            ? _c(
                "b-td",
                { style: `width: 15%` },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "text",
                      value: `CustomField${_vm.field.id}`,
                      disabled: "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.field.id > 0
            ? _c(
                "b-td",
                { staticStyle: { "vertical-align": "middle", width: "2%" } },
                [
                  _c("b-form-checkbox", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.left",
                        modifiers: { hover: true, left: true },
                      },
                    ],
                    attrs: { title: _vm.checkboxTitle, size: "lg" },
                    on: {
                      change: function ($event) {
                        return _vm.handleCheckboxChanged($event, "disabled")
                      },
                    },
                    model: {
                      value: _vm.field.disabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.field, "disabled", $$v)
                      },
                      expression: "field.disabled",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.field.id === 0 && _vm.existDataAlreadySubmitted
            ? _c("b-td")
            : _vm._e(),
          _c(
            "b-td",
            {
              style: `width: ${
                _vm.field.id > 0 ? (_vm.isDev ? "25" : "30") : "32"
              }%`,
            },
            [
              _c("b-form-input", {
                ref: "label",
                class: {
                  "is-invalid": _vm.$v.field.label.$error,
                },
                attrs: { type: "text", autocomplete: "off" },
                on: {
                  keyup: function ($event) {
                    return _vm.handleInputChanged("label")
                  },
                },
                model: {
                  value: _vm.field.label,
                  callback: function ($$v) {
                    _vm.$set(_vm.field, "label", $$v)
                  },
                  expression: "field.label",
                },
              }),
            ],
            1
          ),
          _c(
            "b-td",
            { style: `width: ${_vm.isDev ? "25" : "30"}%` },
            [
              _c("v-select", {
                class: { "is-invalid": _vm.$v.field.type.$error },
                attrs: {
                  placeholder: _vm.typePlaceholder,
                  clearable: false,
                  options: _vm.typeOptions,
                  label: "text",
                  reduce: (option) => option.value,
                },
                on: {
                  input: function ($event) {
                    return _vm.handleVSelectChanged($event, "type")
                  },
                },
                model: {
                  value: _vm.field.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.field, "type", $$v)
                  },
                  expression: "field.type",
                },
              }),
            ],
            1
          ),
          _c(
            "b-td",
            { style: `width: ${_vm.isDev ? "25" : "30"}%` },
            [
              !_vm.hideChoice
                ? _c("custom-field-select", {
                    attrs: { field: _vm.field, index: _vm.index },
                    on: {
                      "custom-field-choice-select:to-submit":
                        _vm.onMenuSubmitted,
                      "custom-field-choice-select:to-reload":
                        _vm.onMenuReloaded,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-td",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.right",
                  modifiers: { hover: true, right: true },
                },
              ],
              staticClass: "text-right",
              staticStyle: { "vertical-align": "middle", width: "3.5%" },
              attrs: { title: _vm.FormMSG(4, "Remove this field") },
            },
            [
              _c(_vm.getLucideIcon("MinusCircle"), {
                tag: "component",
                staticClass: "cursor-pointer mr-1",
                attrs: { color: "#EA4E2C", size: 20, "stroke-width": 3 },
                on: { click: _vm.removeItem },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
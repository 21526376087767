<template>
	<div class="w-100 d-flex">
		<!-- <pre>{{ item }}</pre> -->
		<!-- <pre>{{ options }}</pre> -->
		<v-select
			id="supplier-selector"
			:placeholder="selectTitle"
			:options="options"
			label="value"
			:reduce="(option) => option.index"
			:selectable="disableSelectItem"
			class="grow-flex-auto border-radius-supplier"
			:class="{
				'is-invalid': menus.length === 0
			}"
			:clearable="false"
		/><!-- FormMSG(1, '--- Add a new choice ---') -->
		<div class="d-flex flex-row icon-group-border-append">
			<div
				:id="`choice-mandatory-${index}`"
				:style="`width: ${defaultIconWidth}`"
				class="cursor-pointer d-inline-flex justify-content-center align-items-center"
				@click="updateChoice"
			>
				<component :is="getLucideIcon('ListPlus')" color="#00A09C" :size="22" :stroke-width="1.75" />
				<!-- ListChecks -->
				<b-tooltip :show.sync="showTooltip" :target="`choice-mandatory-${index}`" placement="left">
					<div>
						<strong>{{ FormMSG(2, 'Add/Update List Choice') }}</strong>
					</div>
				</b-tooltip>
			</div>
			<div
				style="border-left: 1px solid #cad0d7"
				:style="`width: ${defaultIconWidth}`"
				class="cursor-pointer d-inline-flex justify-content-center align-items-center"
				v-b-tooltip.hover.top
				:title="FormMSG(3, 'Delete')"
				@click="remove"
				v-if="isDeletable"
			>
				<component :is="getLucideIcon('XCircle')" color="#EA4E2C" :size="18" :stroke-width="2.25" />
			</div>
		</div>
		<choice-modal
			v-model="isCustomFieldChoiceModalOpen"
			:field="item"
			@custom-field-choice-modal:closed="onCustomFieldChoiceModalClosed"
			@custom-field-choice-modal:choice-list-submitted="onCustomFieldChoiceListSubmitted"
			@custom-field-choice-modal:choice-list-reloaded="onCustomFieldChoiceListReloaded"
		/>
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import ChoiceModal from '@/components/CustomField/Choice/FormModal';
import { noTilde } from '~utils';

export default {
	name: 'CustomFieldChoiceSelect',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		ChoiceModal
	},
	props: {
		field: {
			type: Object,
			required: false,
			default: null
		},
		index: {
			type: Number,
			required: false,
			default: 0
		}
	},
	computed: {
		selectTitle() {
			return this.FormMSG(1, 'Total record') + ' ' + '(' + this.menus.length + ')';
		},
		options() {
			return [
				{
					index: null,
					value: this.selectTitle
				},
				...this.menus
			];
		}
	},
	data() {
		return {
			defaultIconWidth: '34px',
			isDeletable: false,
			isCustomFieldChoiceModalOpen: false,
			menus: [],
			showTooltip: true,
			item: null
		};
	},
	methods: {
		updateChoice() {
			this.item = {
				...this.item,
				customFieldMenuChoiceList: this.menus
			};

			this.isCustomFieldChoiceModalOpen = true;
		},
		onCustomFieldChoiceModalClosed() {
			this.isCustomFieldChoiceModalOpen = false;
		},
		remove() {
			alert('Remove');
		},
		disableSelectItem(item) {
			return false;
		},
		onCustomFieldChoiceListSubmitted(payload) {
			this.menus = this.menus.filter((menu) => !_.isEmpty(menu.value));

			if (this.menus.length === 0) {
				this.menus = payload;
			} else {
				for (let i = 0; i < this.menus.length; i++) {
					for (let j = 0; j < payload.length; j++) {
						if (+this.menus[i].index === +payload[j].index) {
							this.menus[i] = {
								...this.menus[i],
								value: payload[j].value
							};
							payload.splice(j, 1);
						}
					}
				}

				if (payload.length > 0) {
					for (const item of payload) {
						this.menus.push(item);
					}
				}
			}

			this.$emit('custom-field-choice-select:to-submit', this.menus);
			this.isCustomFieldChoiceModalOpen = false;
		},
		onCustomFieldChoiceListReloaded() {
			this.$emit('custom-field-choice-select:to-reload', this.menus);
		}
	},
	watch: {
		field: {
			handler(value) {
				if (!_.isNil(value)) {
					this.menus = _.isArray(value.customFieldMenuChoiceList) ? value.customFieldMenuChoiceList : [];
					for (let i = 0; i < this.menus.length; i++) {
						this.menus[i].index = i;
						this.menus[i].customFieldMenuChoiceId = +this.menus[i].id;
					}
					this.showTooltip = +value.id === 0;
					this.item = value;
					// console.log({ menus: this.menus });
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss" scoped></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.choiceTitle,
        size: "lg",
        centered: "",
        "cancel-variant": "light",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { hidden: _vm.emitEventClose, shown: _vm.reloadData },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "sm",
                        variant: "custom-outline-gray",
                        disabled: _vm.watingSubmitForm,
                        block: "",
                      },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(2, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "sm",
                        variant: "primary",
                        disabled:
                          _vm.watingSubmitForm || _vm.choiceList.length === 0,
                        block: "",
                      },
                      on: { click: _vm.dispatchEvent },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _c("b-spinner", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.watingSubmitForm,
                                expression: "watingSubmitForm",
                              },
                            ],
                            attrs: { small: "" },
                          }),
                          _c(
                            "div",
                            {
                              class: `${_vm.watingSubmitForm ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(3, "Save")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "px-0",
          class: {
            "container-layout": _vm.$screen.width > 576,
            "container-mobile": _vm.$screen.width <= 576,
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "w-100" },
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "card-border-blue-light mb-0",
                        staticStyle: { "background-color": "#f8f9fb" },
                        attrs: { "no-body": "" },
                      },
                      [
                        _c("choice-item-form", {
                          ref: "form",
                          attrs: {
                            field: _vm.field,
                            "is-data-exist": _vm.isDataExist,
                            "choice-list": _vm.choiceList,
                          },
                          on: {
                            "custom-field-choice-form-list:submit":
                              _vm.onItemListSubmitted,
                            "custom-field-choice-form-list:reload":
                              _vm.onItemListReloaded,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div class="animated fadeIn">
		<b-form @submit.prevent="submitForm">
			<b-row>
				<b-col class="d-flex flex-column justify-content-center align-items-center">
					<b-table-simple small :borderless="true" v-if="$screen.width >= 992 && items.length > 0">
						<b-thead style="color: rgba(6, 38, 62, 0.84)">
							<b-tr style="line-height: 8px">
								<b-th></b-th>
								<b-th v-if="existDataAlreadySubmitted && items.length > 0 && isDev">{{ FormMSG(10, 'ExportId') }}</b-th>
								<b-th v-if="existDataAlreadySubmitted && items.length > 0"></b-th>
								<b-th>{{ FormMSG(3, '"Label" Name') }}</b-th>
								<b-th>{{ FormMSG(4, 'Type') }}</b-th>
								<b-th>
									<span>{{ FormMSG(5, 'Choices') }}</span>
								</b-th>
								<b-th></b-th>
							</b-tr>
						</b-thead>
						<b-tbody class="text-center">
							<item-form
								v-for="(item, i) in items"
								:ref="generateSecureId(`field-item-form-${i}`)"
								:key="i"
								:index="i"
								:item="item"
								:exist-data-already-submitted="existDataAlreadySubmitted"
								@custom-field-item-form:updated="onLineTableChanged"
								@custom-field-item-form:removed="onLineTableRemoved"
								@custom-field-item-form:hide-choice="onLabelChoiceHidden"
							/>
						</b-tbody>
					</b-table-simple>
					<div class="text-center py-3" style="font-size: 1rem" v-if="$screen.width >= 992 && items.length === 0">
						{{ FormMSG(2, 'Please, add a new field') }}
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="d-flex justify-content-end w-100">
					<b-button size="sm" variant="outline-primary" class="w-200-px d-flex justify-content-center align-items-center" @click="addNewField">
						<component :is="getLucideIcon('PlusCircle')" :size="16" :stroke-width="2" />
						<div class="px-2" style="margin-bottom: -2px">
							{{ FormMSG(1, 'Add new field') }}
						</div>
					</b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					class="d-flex justify-content-center mt-2"
					sm="4"
					md="4"
					lg="4"
					xl="4"
					offset-sm="5"
					offset-md="5"
					offset-lg="5"
					offset-xl="5"
					:style="`display: ${displayAddBtn ? 'block' : 'none'} !important;`"
				>
					<b-button ref="submit" type="submit" size="sm" variant="outline-primary" class="w-138-px" block>{{ FormMSG(6, 'Save') }}</b-button>
				</b-col>
			</b-row>
		</b-form>
		<b-modal
			header-class="header-class-modal-doc-package"
			header-bg-variant="warning"
			:title="FormMSG(8, 'Warning')"
			class="modal-warning"
			v-model="warningMissingFlagItemModal"
			@ok="warningMissingFlagItemModal = false"
			ok-variant="warning"
			ok-only
		>
			<div style="font-size: 1rem">
				{{ FormMSG(9, 'Please fill the field(s) required (coloring red) or remove one or all of them.') }}
			</div>
		</b-modal>
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import ItemForm from '@/components/CustomField/CustomFieldItemForm';
import { generateSecureId } from '@/shared/utils';
import { addUpdCustomFields, deleteCustomFields } from '@/cruds/custom-field.crud';
// import { CUSTOM_FIELD_TYPE } from '@/shared/constants';
import { store } from '@/store';
import { CUSTOM_FIELD_PARENT_TYPE } from '@/shared/constants';

export default {
	name: 'CustomFieldForm',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		ItemForm
	},
	props: {
		fieldList: {
			type: Array,
			required: true,
			default: () => []
		},
		displayAddBtn: {
			type: Boolean,
			required: false,
			default: false
		},
		isDataExist: {
			type: Boolean,
			required: false,
			default: false
		},
		parentType: {
			type: Number,
			required: true,
			default: 0
		}
	},
	computed: {
		defaultLanguage() {
			return store.appLanguage();
			/**
			 * @TODO
			 * To make dynamic
			 * By now, lets start with 0 = English
			 */
			// return 0;
		},
		isDev() {
			return store.isDev();
		}
	},
	data() {
		return {
			items: [],
			generateSecureId,
			isValidFields: [],
			addUpdFieldItems: [],
			existDataAlreadySubmitted: false,
			warningMissingFlagItemModal: false,
			hideChoice: true,
			store,
			CUSTOM_FIELD_PARENT_TYPE
		};
	},
	mounted() {
		this.isValidFields = [];
	},
	methods: {
		async submitForm(e) {
			e.preventDefault();

			if (!this.validFields()) {
				this.warningMissingFlagItemModal = true;

				return;
			}

			let inputs = [];
			for (const fieldItem of this.addUpdFieldItems) {
				const item = _.omit(fieldItem, ['id', 'index']);

				inputs.push(item);
			}

			// console.log({ submitFields: inputs });
			await this.manageAddUpdateAction(inputs);
		},
		async manageAddUpdateAction(inputs) {
			this.$emit('custom-field-form:waiting', true);

			try {
				this.items = await addUpdCustomFields(inputs);
				// this.createToastForMobile(this.FormMSG(10, 'Success'), this.FormMSG(11, 'Custom field(s) added/updated successfully.'), '');
				this.addUpdFieldItems = [];
				this.$emit('custom-field-form:reload');
			} catch (error) {
				console.error({ customFieldFormSubmitError: error });
				this.$emit('custom-field-form:close-modal');
			}

			this.$emit('custom-field-form:waiting', false);
		},
		addNewField() {
			const field = {
				id: 0,
				customFieldId: 0,
				label: '',
				type: null,
				language: this.defaultLanguage,
				parentType: this.parentType
			};

			this.items.push(field);
			this.isValidFields.push({
				index: this.items.length - 1,
				isValid: false
			});
			this.hideChoice = true;
		},
		onLineTableChanged({ index, item, isLineValid }) {
			// console.log('CFF onLineTableChanged', { index, item, isLineValid });
			if (this.addUpdFieldItems.length === 0) {
				this.addUpdFieldItems.push({
					...item,
					index
				});
			} else {
				const idx = this.addUpdFieldItems.findIndex((fieldItem) => fieldItem.index === index);
				if (idx === -1) {
					this.addUpdFieldItems.push({
						...item,
						index
					});
				} else {
					this.addUpdFieldItems[idx] = Object.assign(this.addUpdFieldItems[idx], item);
				}
			}

			const indexValidator = this.isValidFields.findIndex((valid) => valid.index === index);
			if (indexValidator > -1) {
				this.isValidFields[indexValidator] = {
					...this.isValidFields[indexValidator],
					isValid: isLineValid
				};
			}

			// console.log({ addUpdFieldItems: this.addUpdFieldItems, isValidFields: this.isValidFields });
			this.$emit('custom-field-form:update-data', this.addUpdFieldItems.length > 0);
		},
		onLineTableRemoved({ index, id, label }) {
			if (id === 0) {
				this.items.splice(index, 1);
				const idx = this.addUpdFieldItems.findIndex((fieldItem) => fieldItem.index === index);
				if (idx !== -1) {
					this.addUpdFieldItems.splice(idx, 1);
				}

				const indexValidator = this.isValidFields.findIndex((valid) => valid.index === index);
				if (indexValidator !== -1) {
					this.isValidFields.splice(indexValidator, 1);
				}
			} else {
				const action = async () => {
					await deleteCustomFields([id])
						.then(() => {
							this.items.splice(index, 1);
							const idx = this.addUpdFieldItems.findIndex((fieldItem) => fieldItem.index === index);
							if (idx !== -1) {
								this.addUpdFieldItems.splice(idx, 1);
							}

							const indexValidator = this.isValidFields.findIndex((valid) => valid.index === index);
							if (indexValidator !== -1) {
								this.isValidFields.splice(indexValidator, 1);
							}
							this.$emit('custom-field-form:remove', { id, label });
						})
						.catch((error) => {
							console.error({ customFieldFormRemoveError: error });
						});
				};
				this.confirmModal(action, this.FormMSG(7, 'Are you sure to remove this field ?'), 'danger');
			}

			if (this.items.length === 0) {
				this.existDataAlreadySubmitted = false;
			} else {
				const existTrueData = this.items.filter((fieldItem) => +fieldItem.id > 0);
				this.existDataAlreadySubmitted = existTrueData.length > 0;
			}
		},
		validFields() {
			// console.log({ isValidFields: this.isValidFields });
			if (this.isValidFields.length === 0) return true;

			let validFields = [];
			for (const valid of this.isValidFields) {
				validFields.push(valid.isValid);
			}
			const isValidFields = validFields.join(',');

			return !/false/.test(isValidFields);
		},
		onLabelChoiceHidden(payload) {
			this.hideChoice = payload;
		}
	},
	watch: {
		fieldList: {
			handler(value) {
				this.items = value;
				let inc = 0;
				for (const item of this.items) {
					if (+item.id > 0) {
						const indexValidator = this.isValidFields.findIndex((valid) => valid.index === inc);
						if (indexValidator === -1) {
							this.isValidFields.push({
								index: inc,
								isValid: true
							});
						}
						inc++;
					}
				}
				// console.log({ customFields: this.isValidFields });
			},
			deep: true,
			immediate: true
		},
		isDataExist: {
			handler(value) {
				this.existDataAlreadySubmitted = value;
			},
			deep: true,
			immediate: true
		}
	},
	validations() {}
};
</script>
<style lang="scss" scoped></style>

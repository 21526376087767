var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.choice
    ? _c(
        "b-tr",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-td",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.left",
                  modifiers: { hover: true, left: true },
                },
              ],
              staticStyle: { "vertical-align": "middle", width: "3.5%" },
              attrs: { title: _vm.FormMSG(4, "Drag && Drop") },
            },
            [
              _c(_vm.getLucideIcon("LayoutGrid"), {
                tag: "component",
                staticClass: "cursor-pointer mr-1",
                attrs: { color: "#CAD0D7", size: 22, "stroke-width": 2.5 },
              }),
            ],
            1
          ),
          _vm.choice.id > 0
            ? _c(
                "b-td",
                { staticStyle: { "vertical-align": "middle", width: "2%" } },
                [
                  _c("b-form-checkbox", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.left",
                        modifiers: { hover: true, left: true },
                      },
                    ],
                    attrs: { title: _vm.checkboxTitle, size: "lg" },
                    on: {
                      change: function ($event) {
                        return _vm.handleCheckboxChanged($event, "disabled")
                      },
                    },
                    model: {
                      value: _vm.choice.disabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.choice, "disabled", $$v)
                      },
                      expression: "choice.disabled",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.choice.id === 0 && _vm.existDataAlreadySubmitted
            ? _c("b-td")
            : _vm._e(),
          _c(
            "b-td",
            { style: `width: ${_vm.choice.id > 0 ? "91" : "93"}` },
            [
              _c("b-form-input", {
                ref: "value",
                class: {
                  "is-invalid": _vm.$v.choice.value.$error,
                },
                attrs: { type: "text", autocomplete: "off" },
                on: {
                  keyup: function ($event) {
                    return _vm.handleInputChanged("value")
                  },
                },
                model: {
                  value: _vm.choice.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.choice, "value", $$v)
                  },
                  expression: "choice.value",
                },
              }),
            ],
            1
          ),
          _vm.CUSTOM_FIELD_TYPE.Radiobutton !== _vm.field.type
            ? _c(
                "b-td",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right",
                      modifiers: { hover: true, right: true },
                    },
                  ],
                  staticClass: "text-right",
                  staticStyle: { "vertical-align": "middle", width: "3.5%" },
                  attrs: { title: _vm.FormMSG(1, "Remove") },
                },
                [
                  _c(_vm.getLucideIcon("MinusCircle"), {
                    tag: "component",
                    staticClass: "cursor-pointer mr-1",
                    attrs: { color: "#EA4E2C", size: 20, "stroke-width": 3 },
                    on: { click: _vm.removeItem },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<b-tr class="animated fadeIn" v-if="field">
		<b-td style="vertical-align: middle; width: 3.5%" v-b-tooltip.hover.left :title="FormMSG(1, 'Drag && Drop')">
			<component :is="getLucideIcon('LayoutGrid')" color="#CAD0D7" class="cursor-pointer mr-1" :size="22" :stroke-width="2.5" />
		</b-td>
		<b-td v-if="+field.id > 0 && existDataAlreadySubmitted && isDev" :style="`width: 15%`">
			<b-form-input type="text" :value="`CustomField${field.id}`" disabled />
		</b-td>
		<b-td style="vertical-align: middle; width: 2%" v-if="field.id > 0">
			<b-form-checkbox
				v-model="field.disabled"
				:title="checkboxTitle"
				v-b-tooltip.hover.left
				size="lg"
				@change="handleCheckboxChanged($event, 'disabled')"
			/>
		</b-td>
		<b-td v-if="field.id === 0 && existDataAlreadySubmitted"></b-td>
		<b-td :style="`width: ${field.id > 0 ? (isDev ? '25' : '30') : '32'}%`">
			<b-form-input
				ref="label"
				type="text"
				autocomplete="off"
				v-model="field.label"
				:class="{
					'is-invalid': $v.field.label.$error
				}"
				@keyup="handleInputChanged('label')"
			/>
		</b-td>
		<b-td :style="`width: ${isDev ? '25' : '30'}%`">
			<v-select
				:placeholder="typePlaceholder"
				:clearable="false"
				v-model="field.type"
				:options="typeOptions"
				label="text"
				:reduce="(option) => option.value"
				:class="{ 'is-invalid': $v.field.type.$error }"
				@input="handleVSelectChanged($event, 'type')"
			/>
		</b-td>
		<b-td :style="`width: ${isDev ? '25' : '30'}%`">
			<custom-field-select
				:field="field"
				:index="index"
				@custom-field-choice-select:to-submit="onMenuSubmitted"
				@custom-field-choice-select:to-reload="onMenuReloaded"
				v-if="!hideChoice"
			/>
		</b-td>
		<b-td style="vertical-align: middle; width: 3.5%" class="text-right" v-b-tooltip.hover.right :title="FormMSG(4, 'Remove this field')">
			<component :is="getLucideIcon('MinusCircle')" color="#EA4E2C" class="cursor-pointer mr-1" :size="20" :stroke-width="3" @click="removeItem" />
		</b-td>
	</b-tr>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import _ from 'lodash';
import CustomFieldSelect from '@/components/CustomField/Choice/Select';
import { INPUT_TYPE, CUSTOM_FIELD_TYPE } from '@/shared/constants';
import { noTilde } from '~utils';
import { store } from '@/store';
// import {  } from '@/cruds/custom-field.crud';

export default {
	name: 'CustomFieldItemForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: {
		CustomFieldSelect
	},
	props: {
		item: {
			type: Object,
			required: false,
			default: null
		},
		index: {
			type: Number,
			required: false,
			default: 0
		},
		existDataAlreadySubmitted: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		checkboxTitle() {
			return this.field.disabled === false ? this.FormMSG(2, 'Disable') : this.FormMSG(3, 'Enable');
		},
		typePlaceholder() {
			return this.FormMSG(5, 'Please, select a type');
		},
		typeOptions() {
			let menus = this.FormMenu(2014);

			menus.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: null,
					text: this.typePlaceholder
				},
				...menus
			];
		},
		isDev() {
			return store.isDev();
		}
	},
	data() {
		return {
			field: null,
			hideChoice: true,
			menus: null
		};
	},
	mounted() {
		this.$refs.label.focus();
	},
	methods: {
		removeItem() {
			this.$emit('custom-field-item-form:removed', {
				index: this.index,
				id: +this.field.id,
				label: this.field.label
			});
			// console.log({ removeItem: this.field });
		},
		handleCheckboxChanged(payload, fieldName) {
			this.field[fieldName] = payload;

			this.field = {
				...this.field,
				index: this.index,
				customFieldMenuChoiceList: this.menus
			};

			this.$emit('custom-field-item-form:updated', {
				index: this.index,
				item: {
					...this.field,
					customFieldMenuChoiceList: this.menus
				}
			});
		},
		handleInputChanged(fieldName, type = 0) {
			if (type === INPUT_TYPE.INTEGER) {
				this.field[fieldName] = +this.field[fieldName];
			} else if (type === INPUT_TYPE.FLOAT) {
				this.field[fieldName] = parseFloat(this.field[fieldName]);
			}

			this.field = {
				...this.field,
				index: this.index,
				customFieldMenuChoiceList: this.menus
			};

			// console.log({ field: this.field });

			this.$emit('custom-field-item-form:updated', {
				index: this.index,
				item: {
					...this.field,
					customFieldMenuChoiceList: this.menus
				},
				isLineValid:
					!_.isNil(this.field.type) &&
					[CUSTOM_FIELD_TYPE.Checkbox, CUSTOM_FIELD_TYPE.Dropdown, CUSTOM_FIELD_TYPE.Radiobutton].includes(+this.field.type)
						? !this.$v.$invalid && !_.isNil(this.menus) && _.isArray(this.menus) && this.menus.length > 0
						: !this.$v.$invalid
			});
		},
		handleVSelectChanged(payload, fieldName) {
			this.field[fieldName] = payload;

			this.field = {
				...this.field,
				index: this.index,
				customFieldMenuChoiceList: this.menus
			};

			/**
			 * 0: Checkboxes
			 * 2: Drop-down
			 * 6: Radio button
			 * @TODO
			 * set variable in constant file
			 */
			if (fieldName === 'type') {
				this.hideChoice = true;
				if (!_.isNil(payload) && [CUSTOM_FIELD_TYPE.Checkbox, CUSTOM_FIELD_TYPE.Dropdown, CUSTOM_FIELD_TYPE.Radiobutton].includes(+payload)) {
					this.hideChoice = false;
				}
				this.$emit('custom-field-item-form:hide-choice', this.hideChoice);
			}

			this.$emit('custom-field-item-form:updated', {
				index: this.index,
				item: {
					...this.field,
					customFieldMenuChoiceList: this.menus
				},
				isLineValid:
					fieldName === 'type' && [CUSTOM_FIELD_TYPE.Checkbox, CUSTOM_FIELD_TYPE.Dropdown, CUSTOM_FIELD_TYPE.Radiobutton].includes(+payload)
						? !this.$v.$invalid && !_.isNil(this.menus) && _.isArray(this.menus) && this.menus.length > 0
						: !this.$v.$invalid
			});
		},
		onMenuSubmitted(payload) {
			this.menus = payload;

			this.field = {
				...this.field,
				index: this.index,
				customFieldMenuChoiceList: this.menus
			};

			this.$emit('custom-field-item-form:updated', {
				index: this.index,
				item: {
					...this.field,
					customFieldMenuChoiceList: this.menus
				},
				isLineValid: this.menus.length > 0 && !this.$v.$invalid
			});
		},
		onMenuReloaded(payload) {
			this.field = {
				...this.field,
				index: this.index,
				customFieldMenuChoiceList: payload
			};
			// console.log({ onMenuReloaded: payload, index: this.index });
			this.$emit('custom-field-item-form:updated', {
				index: this.index,
				item: {
					...this.field,
					customFieldMenuChoiceList: payload
				},
				isLineValid: payload.length > 0 && !this.$v.$invalid
			});
		}
	},
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					this.field = _.cloneDeep(value);
					this.menus = this.field.customFieldMenuChoiceList;
					// console.log({ value: this.field });

					this.hideChoice = true;
					if (
						[CUSTOM_FIELD_TYPE.Checkbox, CUSTOM_FIELD_TYPE.Dropdown, CUSTOM_FIELD_TYPE.Radiobutton].includes(+this.field.type) &&
						+this.field.id > 0
					) {
						this.hideChoice = false;
					}

					this.$v.$touch();
				}
			},
			deep: true,
			immediate: true
		}
	},
	validations() {
		return {
			field: {
				label: {
					required,
					min: minLength(2)
				},
				type: {
					required
				}
			}
		};
	}
};
</script>
<style lang="scss" scoped></style>

<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="FormMSG(1, 'Manage Custom Fields')"
		size="xl"
		centered
		cancel-variant="light"
		@hidden="emitEventClose"
		no-close-on-backdrop
		no-close-on-esc
		@shown="reloadData"
	>
		<div
			:class="{
				'container-layout': $screen.width > 576,
				'container-mobile': $screen.width <= 576
			}"
			class="px-0"
		>
			<b-row>
				<b-col>
					<div class="w-100">
						<!-- <pre>parentType = {{ parentType }}</pre> -->
						<!-- <span class="fi fi-fr"></span> <span class="fi fi-gr fis"></span> -->
						<b-alert variant="success" :show="existDataUpdated && fields.length > 0" dismissible>
							<div style="font-size: 1.1rem">{{ FormMSG(8, 'Please, do not forget to save your changes.') }}</div>
						</b-alert>
						<b-card no-body class="card-border-blue-light mb-0" style="background-color: #f8f9fb">
							<custom-field-list
								ref="form"
								:field-list="fields"
								:is-data-exist="isDataExist"
								:parent-type="parentType"
								@custom-field-form:waiting="onCustomFieldSubmitted"
								@custom-field-form:close-modal="onCustomFieldModalClosed"
								@custom-field-form:reload="onCustomFieldRefreshed"
								@custom-field-form:remove="onCustomFieldRemoved"
								@custom-field-form:update-data="onCustomFieldLineUpdated"
							/>
						</b-card>
					</div>
				</b-col>
			</b-row>
		</div>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button
					size="sm"
					variant="custom-outline-gray"
					style="margin-top: 5px"
					class="w-138-px mr-3"
					:disabled="watingSubmitForm"
					@click="emitEventClose"
					block
				>
					{{ FormMSG(2, 'Close') }}
				</b-button>
				<b-button size="sm" variant="primary" class="w-138-px" :disabled="watingSubmitForm || fields.length === 0" @click="dispatchEvent" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-show="watingSubmitForm" small />
						<div :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(3, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import CustomFieldList from '@/components/CustomField/CustomFieldForm';
import { getCustomFields } from '@/cruds/custom-field.crud';

export default {
	name: 'CustomFieldMainModal',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		CustomFieldList
	},
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		parentType: {
			type: Number,
			required: true,
			default: 0
		}
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	data() {
		return {
			watingSubmitForm: false,
			fields: [],
			isDataExist: false,
			existDataUpdated: false
		};
	},
	methods: {
		emitEventClose() {
			this.existDataUpdated = false;
			this.$emit('custom-field-main-modal:closed');
		},
		dispatchEvent(e) {
			e.preventDefault();
			this.$refs['form'].$refs['submit'].click();
		},
		async reloadData() {
			try {
				this.fields = await getCustomFields({ type: this.parentType });
				// console.log({ fieldsCustomFieldMainModal: this.fields });
				this.isDataExist = this.fields.length > 0;
			} catch (error) {
				console.error({ customFieldModalError: error });
			}
		},
		onCustomFieldSubmitted(payload) {
			this.watingSubmitForm = payload;
		},
		onCustomFieldModalClosed() {
			this.emitEventClose();
		},
		async onCustomFieldRefreshed() {
			this.createToastForMobile(this.FormMSG(4, 'Success'), this.FormMSG(5, 'Custom field(s) added/updated successfully.'), '');
			await this.reloadData();
			this.existDataUpdated = false;

			this.$emit('custom-field-main-modal:reload');
		},
		async onCustomFieldRemoved(payload) {
			this.createToastForMobile(this.FormMSG(6, 'Field') + ' :: ' + payload.label, this.FormMSG(7, `Custom field removed successfully.`), '');
			await this.reloadData();

			this.$emit('custom-field-main-modal:reload');
		},
		onCustomFieldLineUpdated(payload) {
			// console.log({ onCustomFieldLineUpdated: payload });
			this.existDataUpdated = payload;
		}
	}
};
</script>
<style lang="scss">
.modal-header .close {
	font-size: 38px;
}
</style>
